<template>
  <v-card class="mx-auto">
    <v-list-item>
      <v-avatar class="avatar date white--text">
        <v-layout column align-center justify-center>
          <div class="text-center">
            {{ ( purchase.ticket.accessFrom || purchase.event.starts) | moment("D") }}
          </div>
          <div>
            {{ month }}
          </div>
        </v-layout>
      </v-avatar>
      <v-list-item-content class="pl-3" style="min-height: 70px">
        <v-list-item-title class="headline">{{
          purchase.event && purchase.event.title
        }}</v-list-item-title>
        <v-list-item-subtitle>{{
          ( purchase.ticket.accessFrom || purchase.event.starts)  | moment("MMMM DD / YYYY / hh a")
        }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-img
      v-if="purchase.event && purchase.event.coverImage"
      :src="purchase.event.coverImage"
    ></v-img>

    <v-card-text
      v-if="purchase.event && purchase.event.shortDescription"
        class="text-truncate"
    >
      {{ purchase.event && purchase.event.shortDescription }}
    </v-card-text>
    <v-card-actions>
      <v-btn text color="#11CDEF" @click="goToPurchaseDetail">
        {{ $t('viewAttendees') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-share-variant</v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-actions v-if="purchase.event && purchase.event.isOnlineEvent">
      <v-btn
        outlined color="#11CDEF" @click="goToOnlineEvent"
         style="width: 100%"
      >
        {{ $t('accessEvent') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import moment from 'moment'
export default {
  name: 'Purchase',
  data: () => ({}),
  props: {
    purchase: {
      type: Object,
      required: true
    }
  },
  computed: {
    month () {
      return moment(this.purchase.ticket.accessFrom || this.purchase.event.starts)
        .format('MMM')
        .substring(0, 3)
    }
  },
  methods: {
    goToPurchaseDetail () {
      this.$router.push({
        name: 'PurchaseDetail',
        params: { id: this.purchase._id }
      })
    },
    goToOnlineEvent () {
      if (this.purchase.event.isOnlineEvent) {
        if (this.purchase.event.hasExternalEventUrl) {
          this.goToPurchaseDetail()
        } else {
          this.$router.push({
            name: 'VenueEventLiveStream',
            params: { id: this.purchase.event.slug }
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.date {
  width: 50px !important;
  height: 50px !important;
  background: #343445;
  border-radius: 100%;
  text-align: center;
  position: relative;
}
</style>
