<template>
  <v-container>
    <!-- start spiner --->
    <v-row align="center" class="ma-3 pt-10" justify="center" v-if="isLoading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
      ></v-progress-circular>
    </v-row>
    <!-- end -->
    <div class="purchases">
      <div class="white" v-if="!isLoggedIn">
        <v-col class="text-center" cols="12">
          <v-icon color="black" size="80">
            mdi-alert-box-outline
          </v-icon>
        </v-col>

        <v-col class="text-center black--text">
          {{ $t("pleaseLoginBeforeViewingYourPurchases") }}
        </v-col>

        <v-col class="text-center">
          <v-btn @click="openKeybeUi()" color="black" outlined x-large>
            {{ $t("login") }}
          </v-btn>
        </v-col>
      </div>
    </div>
    <v-row justify="center" v-if="isLoggedIn">
      <v-col v-for="item in purchases" :key="item._id" cols="12" sm="6">
        <Purchase :purchase="item" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import eventService from '@/services/eventsService'
import { mapState } from 'vuex'
import Purchase from './Purchase'
export default {
  name: 'Purchases',
  components: { Purchase },
  mounted () {
    if (this.isLoggedIn) {
      this.getPurchases()
    }
  },
  data: () => ({
    purchases: [],
    isLoading: false
  }),
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.authKeybe.isLoggedIn
    })
  },
  methods: {
    openKeybeUi () {
      window.location.hash = '/kb-profile'
      // window.keybe.uiOpenModal()
    },
    getPurchases () {
      this.isLoading = true
      eventService
        .getUserPurchases()
        .then((response) => {
          this.purchases = response
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          console.error(error)
        })
    }
  },
  watch: {
    isLoggedIn () {
      if (this.isLoggedIn) {
        this.getPurchases()
      }
    }
  }
}
</script>
